.battle-display {
  display: flex;
  vertical-align: middle;
  align-items: center;
  height: 100%;
}

.battle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin: 20px 0;
}

.battle-side-left {
  flex: 1;
  display: flex;
  width: auto;
  justify-content: right;
  flex-wrap: nowrap;
}

.battle-side-right {
  flex: 1;
  display: flex;
  width: auto;
  justify-content: left;
  flex-wrap: nowrap;
}

.vs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  width: 48px;
}

.vs {
  font-size: 24px;
  font-weight: bold;
}

.vs-animate {
  animation: pulse 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    color: white;
  }
  50% {
    transform: scale(1.7);
    color: pink;
  }
  100% {
    transform: scale(1);
    color: white;
  }
}

.onomatopoeia-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  pointer-events: none;
  animation: cartoon-explosion 1s ease-out forwards;
}

.onomatopoeia-text {
  position: absolute;
  text-shadow: 
    1px 1px #000,
    -1px -1px #000,
    1px -1px #000,
    -1px 1px #000;
  animation: fadeInAndGlow 1.1s ease-out forwards;
}


.battle-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  font-size: 64px; /* Larger emoji during battle */
  animation: shake 0.5s infinite;
}


.battle-animation-fadeout {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  font-size: 64px; /* Larger emoji during battle */
  color: #f00;
  animation: battleFadeOut 1s ease-out forwards;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


@keyframes cartoon-explosion {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 1; }
  50% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
  100% { transform: translate(-50%, -50%) scale(1.5); opacity: 0; }
}

@keyframes fadeInAndGlow {
  0% { opacity: 0; text-shadow: none; }
  50% { opacity: 1; text-shadow: 0 0 10px rgba(255, 255, 255, 1); }
  100% { opacity: 0; text-shadow: none; }
}

@keyframes battleFadeOut {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 0; }
}

.winner-title {
  font-weight: 1000;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.winner {
  word-wrap: break-word;
  font-size: 64px;
  text-align: center;
  width: auto;
  
  /* margin: 10px 0;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: explosion 0.5s ease-in-out;
  max-width: 80vw; */
}

.description {
  text-align: center;
  font-size: 24px;
  margin: 10px 0;
}
