:root {
  --main-font: 'Lato', sans-serif; /* Default to Bebas Neue */
  --title-button-font: 'Lato', sans-serif;
  /* --tertiary-font: 'Oswald', sans-serif; */
  
  --primary-color: #0476c1;
  --dark-primary: #015288;
  --secondary-color: #3498db;
  --accent-color: #e74c3c;
  --background-color: #121212;
  --light-bg: #1c1c1c;
  --text-color: #e0e0e0;
  --button-bg-color: var(--primary-color);
  --button-inactive-bg-color: #333;
  --button-hover-bg-color: var(--secondary-color);
  --button-active-bg-color: var(--accent-color);
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--main-font);
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.sidebar.open ~ .main-content {
  margin-left: 350px;
  transition: margin-left 0.3s;
}

.sidebar ~ .main-content {
  margin-left: 70px;
  transition: margin-left 0.3s;
}

@media (max-width: 600px) {  
  .sidebar.open ~ .main-content {
    margin-left: 0px;
  }
  .sidebar ~ .main-content {
    margin-left: 0px;
  }
}

.item-selection {
  max-height: calc(100vh - 160px); /* Adjust based on other elements' height */
  overflow-y: auto;
  width: 100%;
}

.item-selection ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.item-selection li {
  flex: 1 1 calc(25% - 40px);
  max-width: 180px;
  height: auto;
  margin: 10px;
  padding: 10px;
  background: #1f1f1f;
  cursor: pointer;
  transition: background 0.1s, width 0.1s, height 0.1s, margin 0.1s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-sizing: border-box;
}

.item-selection li:hover {
  background: #333;
}

.item-selection li.selected {
  background: var(--primary-color);
  color: white;
  font-weight: bold;
}

.item-selection li.disabled {
  pointer-events: none;
}

.loading-circle {
  border: 4px solid #3498db;
  border-top: 4px solid #f3f3f3;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.emoji-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  width: 100%; /* Ensure it takes the full width of the parent */
  height: 100%; /* Ensure it takes the full height of the parent */
}

.item {
  position: relative;
  user-select: none;
}

.item .emoji {
  transition: font-size 0.1s;
}

.item .item-name {
  font-size: 20px;
  transition: font-size 0.1s;
}

.item-selection li .emoji {
  font-size: calc(1vw + 20px);
  transition: font-size 0.1s;
}

@media (max-width: 600px) {
  .item-selection li .emoji {
    font-size: calc(2vw + 16px);
  }
}

.item-selection li .item-name {
  margin-top: 10px;
  font-size: 20px;
  transition: font-size 0.1s;
}

.item-selection li {
  width: 150px;
  height: 150px;
  margin: 8px;
}

.item-selection li .emoji {
  font-size: 72px;
}

.item-selection li .item-name {
  font-size: 18px;
}

@media (max-width: 900px) {
  .item-selection li {
    width: 120px;
    height: 120px;
    margin: 6px;
  }
  .item-selection li .emoji {
    font-size: 56px;
  }
  .item-selection li .item-name {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .item-selection li {
    width: 90px;
    height: 90px;
    margin: 4px;
  }
  .item-selection li .emoji {
    font-size: 42px;
  }
  .item-selection li .item-name {
    font-size: 14px;
  }
}

.add-item {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.add-item input {
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: white;
  font-family: var(--main-font);
  font-size: 18px;
}

.add-item button {
  padding: 10px;
  background: var(--button-bg-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-family: var(--main-font);
  font-size: 18px;
}

.add-item button:disabled {
  background: var(--button-inactive-bg-color);
  cursor: not-allowed;
}

.add-item button:not(:disabled):hover {
  background: var(--button-hover-bg-color);
}

.confirm-button {
  margin: 20px 0;
  padding: 18px 36px;
  font-family: var(--main-font);
  font-size: 24px;
  cursor: pointer;
  transition: background 0.3s;
  background: var(--button-bg-color);
  color: white;
  border: none;
  border-radius: 5px;
  text-align: center;
}

.next-button {
  margin: 20px 0;
  padding: 20px 40px;
  font-size: 24px;
  font-family: var(--main-font);
  cursor: pointer;
  transition: background 0.3s;
  background: var(--button-bg-color);
  color: white;
  border: none;
  border-radius: 5px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.confirm-button:disabled, .next-button:disabled {
  background: #333;
  cursor: not-allowed;
}

.confirm-button:not(:disabled):hover, .next-button:not(:disabled):hover {
  background: var(--button-hover-bg-color);
}

.user-selection {
  margin: 20px 0;
  text-align: center;
  font-family: var(--main-font);
  font-size: 24px;
}

.fade-out {
  opacity: 0;
  transition: opacity .25s;
}

.fade-in {
  opacity: 1;
  transition: opacity .25s;
}

@keyframes explosion {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.5); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.credits {
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
  color: var(--text-color);
  font-family: var(--main-font);
}

.credits a {
  /* color: var(--secondary-color); */
  color: #cccccc; /* Set the text color to white initially */
  background-color: black; /* Set a black background color for better contrast */
  mix-blend-mode: difference; /* This mode will invert the colors behind the text */
  font-weight: bolder;
  text-decoration: none;
}

.credits a:hover {
  color: #aaaaaa;
  /* text-decoration: underline; */
}