/* EmojiBackground.css */
.emoji-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.emoji {
    position: absolute;
    bottom: -10rem; /* Start below the bottom of the screen */
    font-size: 5rem;
    opacity: 0.8;
    animation-name: scrollUp;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes scrollUp {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-130vh); /* Move above the top of the screen */
    }
}
