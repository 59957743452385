/* Use the existing CSS provided for staggered animation */
:root {
    --emoji-size: calc(min(100vw, 100vh) * 1); /* Scaling the emoji size based on the minimum dimension */
    --animation-distance-start: calc(var(--emoji-size) * 1.1); /* Starting point for the animation */
    --animation-distance-end: calc(var(--emoji-size) * -0.05); /* End point for the animation */
    --animation-distance-mid1: calc(var(--animation-distance-start) + (var(--animation-distance-end) - var(--animation-distance-start)) * 0.1); /* 10% of the animation */
    --animation-distance-mid2: calc(var(--animation-distance-start) + (var(--animation-distance-end) - var(--animation-distance-start)) * 0.9); /* 90% of the animation */
  }
  
  .emoji-animated {
    position: absolute;
    top: 50%;
    opacity: 0.2;
    font-size: var(--emoji-size);
    transform: translateY(-50%) rotate(45deg);    
    will-change: transform;
  }
  
  .emoji-animated0 {
    animation: moveLeft0 4s ease-in-out forwards;
  }
  
  .emoji-animated1 {
    animation: moveLeft1 4s ease-in-out forwards;
  }
  
  .emoji-animated2 {
    animation: moveLeft2 4s ease-in-out forwards;
  }
  
  @keyframes moveLeft0 {
    0% {
      transform: translateX(var(--animation-distance-start)) translateY(-50%) rotate(45deg);
    }
    40% {
      transform: translateX(var(--animation-distance-mid1)) translateY(-50%) rotate(45deg);
    }
    80% {
      transform: translateX(var(--animation-distance-mid2)) translateY(-50%) rotate(45deg);
    }
    100% {
      transform: translateX(var(--animation-distance-end)) translateY(-50%) rotate(45deg);
    }
  }
  
  @keyframes moveLeft1 {
    0% {
      transform: translateX(var(--animation-distance-start)) translateY(-50%) rotate(45deg);
    }
    40% {
      transform: translateX(calc(var(--animation-distance-mid1) + 5%)) translateY(-50%) rotate(45deg);
    }
    80% {
      transform: translateX(calc(var(--animation-distance-mid2) + 5%)) translateY(-50%) rotate(45deg);
    }
    100% {
      transform: translateX(var(--animation-distance-end)) translateY(-50%) rotate(45deg);
    }
  }
  
  @keyframes moveLeft2 {
    0% {
      transform: translateX(var(--animation-distance-start)) translateY(-50%) rotate(45deg);
    }
    40% {
      transform: translateX(calc(var(--animation-distance-mid1) + 10%)) translateY(-50%) rotate(45deg);
    }
    80% {
      transform: translateX(calc(var(--animation-distance-mid2) + 10%)) translateY(-50%) rotate(45deg);
    }
    100% {
      transform: translateX(var(--animation-distance-end)) translateY(-50%) rotate(45deg);
    }
  }
  