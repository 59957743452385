.float-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none; /* Ensure it doesn't interfere with user interactions */
  }
  
  .float-emoji {
    position: absolute;
    font-size: 3vw;
    opacity: 0.2;
    animation: float 10s infinite ease-in-out;
  }
  
  @keyframes float {
    0% {
      transform: translate(0, 0) scale(1);
    }
    50% {
      transform: translate(var(--translate-x), var(--translate-y)) scale(1.5);
    }
    100% {
      transform: translate(0, 0) scale(1);
    }
  }
  