.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 70px;
  height: 100vh;
  transition: width 0.3s;
  background: var(--light-bg);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.sidebar.open {
  width: 350px;
}

.sidebar .hamburger {
  font-size: 30px;
  padding: 20px;
  cursor: pointer;
  color: #e0e0e0;
  background: none;
  border: none;
}

.sidebar .menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}

.sidebar .menu.hidden {
  display: none;
}

.menu button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 20px;
}

.menu button:disabled {
  background: #333;
  cursor: not-allowed;
}

.menu button:not(:disabled):hover {
  background: var(--dark-primary);
}

/* Media query for small screens */
@media (max-width: 600px) {  
  .sidebar:not(.open) {
    background: #00000000;
    height: 80px;
  }

  .sidebar.open {
    width: 100vw; /* Full width when expanded */
    opacity: 1;
  }
  
  .main-content {
    margin-left: 60px; /* Adjust for collapsed sidebar width */
  }
  
  .sidebar.open ~ .main-content {
    margin-left: 0; /* Adjust for full-width sidebar */
  }
}