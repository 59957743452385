.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9vw, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(9vw, 1fr));
    gap: 1vw;
    width: 100vw;
    height: 100vh;
    place-items: center;
    overflow: visible;
  }
  
  .grid-emoji {
    font-size: 5vw;
    opacity: 0.2;
    animation: rotations 4s linear infinite;
  }
  
  @keyframes rotations {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  