.bg {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  animation-fill-mode: forwards; /* Ensure they remain visible after the animation */
}

@keyframes appearGlow {
  0% {
    opacity: 0;
    filter: brightness(1.4);
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    filter: brightness(1.4);
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    filter: brightness(1);
    transform: scale(1);
  }
}

@keyframes wiggleAppearThenShrinkMove {
  0% {
    opacity: 0.5;
    transform: translate(0%, 0%) rotate(0deg) scale(0.0);
  }
  20% {
    opacity: 0.5;
    transform: translate(-5%, 0%) rotate(-15deg) scale(1.00);
  }
  40% {
    opacity: 1;
    transform: translate(5%, 0%) rotate(15deg) scale(1.00);
  }
  60% {
    opacity: 1;
    /* top: 50%; */
    /* left: 50%; */
    width: 80vmin;
    height: 80vmin;
    transform: translate(-5%, 0%) rotate(-15deg) scale(1.00);
  }
  80% {
    /* top: 25%; */
    /* left: 50%; */
    width: 30vmin;
    height: 30vmin;
    transform: translate(0%, 0%) rotate(0deg) scale(1);
  }
  100% {
    opacity: 1;
    /* top: 25%; */
    /* left: 50%; */
    width: 40vmin;
    height: 40vmin;
    transform: translate(0%, 0%);
  }
}


@keyframes buttonPop {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.4);
  }
  70% {
    transform: scale(0.8);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes buttonWiggle {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(2deg);
  }
  75% {
    transform: rotate(-1.5deg);
  }
  80% {
    transform: rotate(-1deg);
  }
}

.bgUniverse {
  z-index: -9;
  background-image: url('/public/universe.webp'), url('/public/universe.png');
  background-image: image-set(
    url('/public/universe.webp') type('image/webp'),
    url('/public/universe.png') type('image/png')
  );
  opacity: 1;
}

.bgRockLayer {
  z-index: -8;
  background-image: url('/public/rock_layer.webp'), url('/public/rock_layer.png');
  background-image: image-set(
    url('/public/rock_layer.webp') type('image/webp'),
    url('/public/rock_layer.png') type('image/png')
  );
  animation: appearGlow 1s ease-in-out 0s forwards;
}

.bgPaperLayer {
  z-index: -7;
  background-image: url('/public/paper_layer.webp'), url('/public/paper_layer.png');
  background-image: image-set(
    url('/public/paper_layer.webp') type('image/webp'),
    url('/public/paper_layer.png') type('image/png')
  );
  animation: appearGlow 1s ease-in-out .4s forwards;
}

.bgScissorLayer {
  z-index: -6;
  background-image: url('/public/scissor_layer.webp'), url('/public/scissor_layer.png');
  background-image: image-set(
    url('/public/scissor_layer.webp') type('image/webp'),
    url('/public/scissor_layer.png') type('image/png')
  );
  animation: appearGlow 1s ease-in-out 0.8s forwards;
}

.homepageLogo {
  position: relative;
  top: 0%;
  left: 0%;
  width: 80vmin; /* 80% of the smaller dimension */
  height: 80vmin; /* 80% of the smaller dimension */
  background-image: url('/public/big_logo.webp'), url('/public/big_logo.png');
  background-image: image-set(
    url('/public/big_logo.webp') type('image/webp'),
    url('/public/big_logo.png') type('image/png')
  );
  background-size: cover;
  background-position: center;
  transform: translate(0%, 0%);
  animation: wiggleAppearThenShrinkMove 0.9s ease-in-out 1.2s forwards
}

.menu {
  position: relative;
  top: 0%;
  left: 0%;
  /* transform: translateX(10%); */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu button {
  margin: 10px 0;
  padding: 10px 10px;
  font-size: 1.5rem;
  font-weight: bolder ;
  font-family: var(--title-button-font);
  color: var(--text-color);
  background-color: #000000c0;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transform: scale(0); /* Start from 0 scale */
  animation: buttonPop 0.7s ease-in-out forwards, buttonWiggle 0.6s ease-in-out 0.5s 1;
  display: inline-block;
  transition: background 0.3s;
}

@media (max-width: 600px) {
  .menu button {
    margin: 5px 0;
    padding: 5px 5px;
    /* font-size: 1rem; */
  }
}

.menu button:hover {
  background-color: #000000f0;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
}

.menu button:active {
  background-color: #303030d0;
}

.menu button:nth-child(1) {
  animation-delay: 1.75s, 2.5s;
}

.menu button:nth-child(2) {
  animation-delay: 1.85s, 2.6s;
}

.menu button:nth-child(3) {
  animation-delay: 1.95s, 2.7s;
}

.menu button:nth-child(4) {
  animation-delay: 2.05s, 2.8s;
}

.menu button:nth-child(5) {
  animation-delay: 2.15s, 2.9s;
}

